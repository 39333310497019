<template>
  <v-dialog v-model="ImportDialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t("Import") }} {{ $t(formTitle) }}</span>

        <v-spacer></v-spacer>

        <v-icon class="main-color" @click="Close">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <v-container>
          <div v-if="uploadingError">
            <v-row class="buttons-space">
              <v-icon :color="colorIcon" class="iconSpace">attach_file</v-icon>
              <v-spacer></v-spacer>
              <v-icon :color="colorIcon" v-if="successMsg != ''">done</v-icon>
              <v-icon :color="colorIcon" v-if="errorIcon">error</v-icon>

              <v-progress-linear
                rounded
                :color="colorIcon"
                :indeterminate="indeterminate"
                height="10"
                :value="valueProgress"
                striped
              ></v-progress-linear>
            </v-row>

            <v-row
              style="margin-top: 25px !important; margin-bottom: 7px !important"
            >
              <div>
                <p
                  class="success--text text-center margin-auto"
                  role="alert"
                  style="margin-top: -15px !important"
                  v-if="successMsg != ''"
                >
                  {{ successMsg }}
                </p>
                <br />
              </div>
              <div v-if="Object.keys(validation_errors).length !== 0">
                <p v-for="(error, index) in validation_errors" :key="index">
                  <v-icon class="main-color">error</v-icon> {{ error }}
                </p>
              </div>
            </v-row>
            <v-row>
              <v-col sm="6"></v-col>
              <v-col md="3" sm="3">
                <v-btn class="modal-btn-save" @click="Back"
                  >{{ $t("Back") }}
                </v-btn>
              </v-col>

              <v-col md="3" sm="3">
                <v-btn class="modal-btn-cancel" @click="Close"
                  >{{ $t("Close") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="importDiv">
            <v-row class="buttons-space">
              <v-col md="12" sm="12">
                <div class="custom-file" id="upload-photo">
                  <input
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    class="custom-file-input"
                    ref="fileObject"
                  />
                  <label class="custom-file-label" for="customFile">
                    <br
                  /></label>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6"></v-col>
              <v-col md="3" sm="3">
                <v-btn class="modal-btn-save" @click="Back"
                  >{{ $t("Back") }}
                </v-btn>
              </v-col>

              <v-col md="3" sm="3">
                <v-btn class="modal-btn-save" @click.prevent="uploadFile"
                  >{{ $t("Import") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="importFile">
            <v-row class="buttons-space">
              <v-col md="6" sm="6">
                <a v-bind:href="templateUrlLink">
                  <v-btn class="modal-btn-download"
                    >{{ $t("Download Template") }} <br /><v-spacer></v-spacer
                    ><img
                      title="Download File"
                      src="../../../src/assets/icons/download.svg" /></v-btn
                ></a>
              </v-col>
              <v-col md="6" sm="6">
                <v-btn class="modal-btn-import" @click="ShowImportDiv"
                  >{{ $t("Import") }} {{ $t(formTitle) }}
                  <v-spacer></v-spacer>
                  <img
                    title="Import File"
                    src="../../../src/assets/icons/upload-white.svg"
                    class="color"
                /></v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: ["formTitle", "ImportDialog"],
  name: "ImportComponent",
  data() {
    return {
      templateUrlLink: "",
      error: "",
      errorIcon: false,
      successMsg: "",
      validation_errors: {},
      chosenFile: null, // <- initialize the v-model prop
      data: null,
      importDiv: false,
      uploadingError: false,
      importFile: true,
      colorIcon: "blue",
      indeterminate: "indeterminate",
      valueProgress: 10,
    };
  },
  methods: {
    Close() {
      this.reset();
      this.ImportDialog = false;
      this.$emit("childImportToParent", false);
    },
    Back() {
      this.importDiv = false;
      this.importFile = true;
      this.uploadingError = false;
      this.colorIcon = "blue";
    },
    ShowImportDiv() {
      this.importDiv = true;
      this.importFile = false;
      this.uploadingError = false;
      this.colorIcon = "blue";
    },
    reset() {
      this.error = "";
      this.successMsg = "";
      this.validation_errors = {};
      this.errorIcon = false;
      this.importFile = true;
      this.importDiv = false;
      this.uploadingError = false;
      this.colorIcon = "blue";
    },
    uploadFile() {
      let url = "";
      if (this.formTitle == "Teacher") {
        url = "/teachers/import";
      }
      if (this.formTitle == "Grade") {
        url = "/grades/importGrades";
      }
      if (this.formTitle == "Item") {
        url = "/items/import";
      }
      if (this.formTitle == "Student") {
        url = "/students/import";
      }

      if (this.formTitle == "Attendance") {
        url = "/employee/import";
      }
      if (this.formTitle == "Employees") {
        url = "/employee/profiles/import";
      }
      // clear error
      this.reset();
      this.importDiv = false;
      this.uploadingError = true;
      this.importFile = false;
      //   define formdata object
      const fd = new FormData();
      // check if there is a file
      //   console.log(this.$refs.fileObject.files);
      if (this.$refs.fileObject.files.length > 0) {
        // assign a key to the file
        fd.append("file", this.$refs.fileObject.files[0]);
        axios
          .post(this.getApiUrl + url, fd, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            // if validation error
            if (response.data) {
              if (
                response.data.status.error &&
                response.data.status.validation_errors.length != 0 &&
                Object.keys(response.data.status.validation_errors).length != 0
              ) {
                //console.log(response.data.status.validation_errors);
                this.errorIcon = true;
                this.validation_errors = response.data.status.validation_errors;
                this.colorIcon = "red";
                this.valueProgress = 20;
              } else if (
                (response.data.status.error &&
                  response.data.status.validation_errors.length == 0) ||
                (response.data.status.error &&
                  Object.keys(response.data.status.validation_errors).length ===
                    0 &&
                  response.data.status.validation_errors.constructor === Object)
              ) {
                //console.log(response.data.status.validation_errors);
                this.errorIcon = true;
                this.validation_errors = [response.data.status.message];
                this.colorIcon = "red";
                this.valueProgress = 20;
              } else {
                this.successMsg = response.data.status.message;
                this.errorIcon = false;
                this.colorIcon = "green";
                this.valueProgress = 100;
              }
              this.indeterminate = false;
            }
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.valueProgress = 0;
        this.indeterminate = false;
        this.error = "Please select file first";
        this.colorIcon = "red";
        this.errorIcon = true;
        this.validation_errors = ["Please select file first"];
      }
    },
  },
  beforeMount() {
    this.templateUrlLink = "";
    let url = "";
    if (this.formTitle == "Teacher") {
      url = "/teachers/getTemplateLink";
    }
    if (this.formTitle == "Grade") {
      url = "/grades/getTemplateLink";
    }
    if (this.formTitle == "Item") {
      url = "/items/getTemplateLink";
    }

    if (this.formTitle == "Student") {
      url = "/students/getTemplateLink";
    }
    if (this.formTitle == "Attendance") {
      url = "/employee/getTemplateLink";
    }
    if (this.formTitle == "Employees") {
      url = "/employee/profiles/getTemplateLink";
    }
    // console.log(url);
    axios
      .get(this.getApiUrl + url, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        if (response.data.data.url) {
          // console.log(response.data.data.url);
          this.templateUrlLink = response.data.data.url;
        }
      })
      .catch((err) => {
        alert(err);
      });
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.buttons-space {
  margin-bottom: 40px;
  margin-top: 30px;
}
.custom-file {
  width: 100%;
  border: 2px dashed #bbbbbb;
  box-shadow: unset !important;
  .custom-file-label {
    box-shadow: unset !important;
    border: unset;
    background-color: unset !important;
    &::after {
      // display: inline;
      padding: 7px 2;
      background-color: unset !important;
      color: #7297ff;
      margin-right: 190px;
      margin-top: 0%;
    }
  }
}
img {
  height: 20px !important;
  margin: 3px !important;
}
img .color {
  color: #ffffff !important;
}
.iconSpace {
  margin: 1px !important;
}
</style>
